import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Button, Chip, Input, Spacer } from '@nextui-org/react';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { EventLogDetails } from '../utils/event-log-details';
import { getTopLevelDomain } from '../utils/get-top-level-domain';
import logo from '../assets/logo-dark-colored.svg';

import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { GridApi, ICellRendererParams, ColDef } from 'ag-grid-community';

type CrawlrunSearch = {
  crawlRunId?: string;
  apiKey?: string;
};

export const Route = createFileRoute('/')({
  component: IndexPage,
  validateSearch: (search: Record<string, unknown>): CrawlrunSearch => {
    // validate and parse the search params into a typed state
    return {
      crawlRunId: (search.crawlRunId as string) || '',
      apiKey: search._apikey as string,
    };
  },
});

export function formatState(keyValue: string) {
  if (keyValue === 'DATA') {
    return (
      <Chip
        size='sm'
        color='success'
        variant='flat'
      >
        {keyValue}
      </Chip>
    );
  }
  if (keyValue === 'INTERACTION' || keyValue === 'BLOCKED' || keyValue === 'CRASHED') {
    return (
      <Chip
        size='sm'
        color='danger'
        variant='flat'
      >
        {keyValue}
      </Chip>
    );
  }
  if (keyValue === 'NO_DATA') {
    return (
      <Chip
        size='sm'
        color='warning'
        variant='flat'
      >
        {keyValue}
      </Chip>
    );
  }
  return keyValue;
}

function IndexPage() {
  const navigate = useNavigate();
  const routeSearch = Route.useSearch();
  const crawlRunIdFromUrl = routeSearch?.crawlRunId ?? '';
  const apiKey = routeSearch?.apiKey ?? '';

  const [crawlrunId, setCrawlrunId] = React.useState(crawlRunIdFromUrl);
  const [crawlrunIdEdited, setCrawlrunIdEdited] = React.useState(crawlRunIdFromUrl);

  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  // Queries
  const query = useQuery({
    queryKey: ['query', crawlrunId, apiKey],
    refetchOnWindowFocus: false,
    refetchInterval: false,
    queryFn: async (): Promise<EventLogDetails[]> => {
      if (!crawlrunId) {
        return [];
      }

      gridApi?.showLoadingOverlay();
      try {
        const data = await fetch(
          `https://api.${getTopLevelDomain()}/eventlog_new/${crawlrunId}?${apiKey ? `_apikey=${apiKey}` : ''}`,
          {
            credentials: 'include',
          },
        ).then((res) => res.json());

        if (data.code) {
          if (data.code === 2004) {
            window.location.href = `https://enter.${getTopLevelDomain()}/?redirect=${encodeURIComponent(window.location.href)}`;
          }
          throw new Error(data.message);
        }

        return data.map((item: EventLogDetails) => {
          return {
            ...item,
            version: item.version?.substring(0, 6) ?? 'nightmare',
            timestamp: new Date(item.timestamp).toLocaleString(),
          };
        });
      } finally {
        gridApi?.hideOverlay();
      }
    },
  });

  const loadingTemplate = '<span class="ag-overlay-loading-center">Loading...</span>';
  const noRowsTemplate = '<span class="ag-overlay-no-rows-center">No rows to display</span>';

  // Column Definitions: Defines the columns to be displayed.
  const colDefs: ColDef[] = [
    { field: 'taskId', width: 280, cellStyle: { fontSize: '12px' } },
    {
      field: 'retry',
      width: 110,
      floatingFilter: true,
      filter: 'agNumberColumnFilter',
      sortable: true,
    },
    {
      field: 'state',
      width: 150,
      filter: true,
      sortable: true,
      floatingFilter: true,
      cellRenderer: (params: ICellRendererParams) => {
        return formatState(params.value);
      },
    },
    { field: 'version', width: 140, floatingFilter: true, filter: true, sortable: true },
    { field: 'timestamp', width: 200, sortable: true, cellStyle: { fontSize: '12px' } },
  ];

  return (
    <div>
      <div id='logo-container'>
        <div className='max-w-4xl mx-auto p-4'>
          <img
            width={150}
            alt='Import.io'
            src={logo}
          />
        </div>
      </div>
      <div className='max-w-4xl mx-auto p-4 flex'>
        <Input
          type='text'
          label='Crawlrun guid / Crawlrun:task guid'
          size='sm'
          variant='bordered'
          value={crawlrunIdEdited}
          onChange={(e) => {
            setCrawlrunIdEdited(e.target.value);
          }}
        />
        <Spacer x={4} />
        <Button
          size='lg'
          color='secondary'
          radius='sm'
          variant='bordered'
          onClick={() => {
            setCrawlrunId(crawlrunIdEdited);
            void query.refetch();
          }}
          isLoading={query.isLoading}
        >
          Submit
        </Button>
      </div>

      <div className='max-w-4xl mx-auto p-4 flex'>
        {query.isError ? (
          <div>
            <Chip color='danger'>Error</Chip>
            {query.error.message}
          </div>
        ) : null}
        {!query.isError && (
          <div
            className='ag-theme-quartz' // applying the grid theme
            style={{ height: 500, width: '100%' }} // the grid will fill the size of the parent container
          >
            <AgGridReact
              rowData={query.data!}
              columnDefs={colDefs}
              onGridReady={(params) => {
                setGridApi(params.api);
              }}
              onRowClicked={(event) => {
                void navigate({
                  to: '/player',
                  search: {
                    crawlrunGuid: event.data!.crawlRunId,
                    taskGuid: event.data!.taskId,
                    key: event.data!.Key,
                    ...(apiKey && { _apikey: apiKey }),
                  },
                });
                // You can navigate or do other things with the clicked row data here
              }}
              overlayLoadingTemplate={loadingTemplate}
              overlayNoRowsTemplate={noRowsTemplate}
            />
          </div>
        )}
      </div>
    </div>
  );
}
