import {
  DebuggerEvent,
  NetworkLoadingFailedParams,
  NetworkRequestWillBeSentParams,
  NetworkResponseReceivedParams,
} from '../utils/network-event-record';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import JsonView from '@uiw/react-json-view';
import { useState } from 'react';

export interface NetworkTableProps {
  debuggerEvents: DebuggerEvent[];
}

export function NetworkTable(props: NetworkTableProps) {
  const events = props.debuggerEvents || [];

  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [activeRequest, setActiveRequest] = useState<DebuggerEvent | null>(null);

  const items = events
    .filter((event) => event.event.method === 'Network.requestWillBeSent')
    .map((event) => {
      const params = event.event.params as NetworkRequestWillBeSentParams;
      return {
        timestamp: params.timestamp,
        id: params.requestId,
        url: params.request.url,
        method: params.request.method,
        status: 'Pending',
        type: params.type,
        size: 0,
        time: '0',
        error: '',
        statusCode: '',
        requestHeaders: JSON.stringify(params.request.headers),
        responseHeaders: '{}',
      };
    });

  const finishedEvents = events.filter(
    (event) => event.event.method === 'Network.responseReceived',
  );
  finishedEvents.forEach((event) => {
    const params = event.event.params as NetworkResponseReceivedParams;
    const item = items.find((i) => i.id === event.event.params.requestId);
    if (item) {
      item.status = 'Complete';
      item.type = params.type;
      item.size = params.response.encodedDataLength;
      item.time = (params.timestamp - item.timestamp).toFixed(2).toString();
      item.statusCode = params.response.status.toString();
      item.responseHeaders = JSON.stringify(params.response.headers);
    }
  });

  const failedEvents = events.filter((event) => event.event.method === 'Network.loadingFailed');
  failedEvents.forEach((event) => {
    const params = event.event.params as NetworkLoadingFailedParams;
    const item = items.find((i) => i.id === event.event.params.requestId);
    if (item) {
      item.status = 'Failed';
      item.type = 'DATA';
      item.size = 0;
      item.time = (params.timestamp - item.timestamp).toFixed(2).toString();
      item.error = params.errorText;
    }
  });

  const columnDefs: ColDef[] = [
    { headerName: 'URL', field: 'url', floatingFilter: true, filter: true },
    { headerName: 'Method', field: 'method', floatingFilter: true, filter: true },
    { headerName: 'Type', field: 'type', floatingFilter: true, filter: true },
    {
      headerName: 'Status',
      field: 'status',
      floatingFilter: true,
      filter: true,
      cellRenderer: (params: { value: string }) => {
        const status = params.value;
        if (status === 'Complete') {
          return <span className='text-green-600'>{status}</span>;
        } else if (status === 'Failed') {
          return <span className='text-red-600'>{status}</span>;
        }
      },
    },
    { headerName: 'Code', field: 'statusCode', floatingFilter: true, filter: true },
    { headerName: 'Req headers', field: 'requestHeaders', floatingFilter: true, filter: true },
    {
      headerName: 'Response headers',
      field: 'responseHeaders',
      floatingFilter: true,
      filter: true,
    },
    { headerName: 'Size', field: 'size', floatingFilter: true, filter: true },
    { headerName: 'Time', field: 'time' },
    { headerName: 'Timestamp', field: 'timestamp' },
  ];

  return (
    <div
      className='ag-theme-quartz'
      style={{ height: '100%', width: '100%' }}
    >
      <Modal
        size='2xl'
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className='flex flex-col gap-1'>Network request</ModalHeader>
              <ModalBody>
                <JsonView
                  value={activeRequest || {}}
                  displayDataTypes={false}
                  collapsed={false}
                  shortenTextAfterLength={150}
                />
              </ModalBody>
              <ModalFooter>
                <Button onPress={onClose}>Close</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={{ cellStyle: { fontSize: '11px' } }}
        onRowClicked={(event) => {
          setActiveRequest({
            ...event.data,
            requestHeaders: JSON.parse(event.data.requestHeaders),
            responseHeaders: JSON.parse(event.data.responseHeaders),
          });
          onOpen();
        }}
        rowData={items}
      />
    </div>
  );
}
